<template>
  <div v-if="!isLoading">
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')} ${item.code}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteBookingItem', { item: item.code }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CONVERT RESERVA -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.reserve')} ${item.code}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.convertToReserve', { item: item.code }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="reservando"
            @click="confirmConvertReserve()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-simple-table v-if="cotizationsPaginate.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.code') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.client') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.product') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.service') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.oferta') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.vendedor') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('lbl.compra') }}
            </th>
            <th class="text-uppercase"></th>-->
            <th
              v-if="
                permisos.includes('reservas:edit') || permisos.includes('reservas:destroy') || permisos.includes('*')
              "
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(reserva, index) in cotizationsPaginate"
            :key="index"
          >
            <td width="15%">
              <span
                class="d-inline-block text-caption"
                :style="`color: ${$vuetify.theme.themes.light.primary}`"
              >
                {{ reserva.code }}
              </span>
            </td>
            <td>
              <span v-if="reserva.cliente">
                {{ reserva.cliente.name }}
                {{ reserva.cliente.apellidos }}<br />
                <a
                  v-if="reserva.cliente.email"
                  :href="`mailito:${reserva.cliente.email}`"
                >
                  {{ reserva.cliente.email }}
                </a>
                <a
                  v-else-if="reserva.cliente.emails.length > 0"
                  :href="`mailito:${reserva.cliente.emails[0]}`"
                >
                  {{ reserva.cliente.emails[0] }}
                </a>
                <span v-if="reserva.cliente.telefono"> <br />{{ reserva.cliente.telefono }} </span>
              </span>
            </td>
            <td>
              <fragment v-if="reserva.entities">
                <span v-if="reserva.entities.length > 1">
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiPackage }}</v-icon> {{ $t('lbl.paquet') }}
                      </span>
                    </template>
                    <span>
                      <span
                        v-for="(entity, ind) in reserva.entities"
                        :key="ind"
                      >
                        <fragment v-if="entity === 'flights'">
                          <v-icon style="color: #FFF;">{{ icons.mdiAirplaneTakeoff }}</v-icon> {{ $t('lbl.flight')
                          }}<br />
                        </fragment>
                        <fragment v-if="entity === 'cars'">
                          <v-icon style="color: #FFF;">{{ icons.mdiCar }}</v-icon> {{ $t('lbl.car') }} <br />
                        </fragment>
                        <fragment v-if="entity === 'hotels'">
                          <v-icon style="color: #FFF;">{{ icons.mdiBed }}</v-icon> {{ $t('lbl.hotel') }} <br />
                        </fragment>
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <fragment v-if="reserva.entities.includes('flights')">
                    <v-icon>{{ icons.mdiAirplaneTakeoff }}</v-icon> {{ $t('lbl.flight') }}
                  </fragment>
                  <fragment v-else-if="reserva.entities.includes('cars')">
                    <v-icon>{{ icons.mdiCar }}</v-icon> {{ $t('lbl.car') }}
                  </fragment>
                  <fragment v-else-if="reserva.entities.includes('hotels')">
                    <v-icon>{{ icons.mdiBed }}</v-icon> {{ $t('lbl.hotel') }}
                  </fragment>
                </span>
              </fragment>
            </td>
            <td>
              <span v-if="reserva.date_servicio.length > 0">
                {{ reserva.date_servicio[0] | moment('D/MM/Y') }}
              </span>
            </td>
            <td>
              <span :style="`color: ${$vuetify.theme.themes.light.primary}`">
                <!--{{ reserva.price_total | currency }} </span><br />-->
                {{ reserva.pricePayOnline | currency(reserva.car_shop.length > 0 ? (reserva.car_shop[0].data_service.items.currencyCode ? reserva.car_shop[0].data_service.items.currencyCode : '$') : '$') }} </span><br />
              <span v-if="reserva.caducada">{{ $t('lbl.caducada') }}</span>
              <span v-else>{{ $t('lbl.vigente') }}</span>
            </td>
            <td width="20%">
              <span class="d-inline-block text-caption">{{ reserva.afiliado.name }}</span><br />
              {{
                reserva.afiliado.company !== null
                  ? reserva.afiliado.company
                  : reserva.afiliado.nivel === 0
                    ? 'Propietario'
                    : ''
              }}
            </td>
            <!--<td>{{ reserva.date_compra | moment('D/MM/Y') }}</td>-->
            <!--<td>
              <v-btn
                :disabled="
                  !permisos.includes('reservas:edit') || reserva.caducada || reserva.afiliado.nivel !== user.nivel
                "
                :loading="reservando"
                color="primary"
                @click="convertReserve(reserva)"
              >
                <span>{{ $t('btn.reserve') }}</span>
              </v-btn>
            </td>-->
            <td
              v-if="permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')"
              width="10%"
            >
              <v-menu
                v-if="
                  permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')
                "
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="permisos.includes('reservas:edit')"
                    link
                    @click="convertReserve(reserva)"
                  >
                    <v-list-item-title>
                      <v-btn
                        :disabled="
                          !permisos.includes('reservas:edit') ||
                            reserva.caducada ||
                            reserva.afiliado.nivel !== user.nivel
                        "
                        icon
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.reserve') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (permisos.includes('reservas:edit') || permisos.includes('*')) &&
                        !reserva.cancelada &&
                        (reserva.afiliado.nivel === user.nivel || user.nivel <= 0)
                    "
                    link
                    @click="editItem(reserva)"
                  >
                    <v-list-item-title>
                      <v-btn
                        :disabled="
                          !permisos.includes('reservas:edit') ||
                            reserva.caducada ||
                            reserva.afiliado.nivel !== user.nivel
                        "
                        icon
                      >
                        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                      </v-btn>
                      <span>{{ $t('btn.edit') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!--<v-tooltip
                v-if="permisos.includes('reservas:list') || permisos.includes('*')"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showItem(reserva)"
                  >
                    <v-icon>{{ icons.mdiEye }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.see') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="
                  (permisos.includes('reservas:edit') || permisos.includes('*')) &&
                    !reserva.caducada &&
                    reserva.afiliado.nivel === user.nivel
                "
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(reserva)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>-->

              <!--<v-tooltip
                v-if="(permisos.includes('reservas:destroy') || permisos.includes('*')) && !reserva.cancelada"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(reserva)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.cancele') }}</span>
              </v-tooltip>-->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <template v-if="cotizationsPaginate.length === 0">
      <v-row class="mt-5">
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t('msg.noCotizations') }}
            {{ $t('msg.changeFilters') }}
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tipos_product: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    names: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    codes: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiDotsVertical,
      },
      isDialogVisibleDelete: false,
      isDialogVisible: false,
      item: {},
      itemId: null,
      loading: false,
      reservando: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      posCotizador: state => state.app.posCotizador,
      cotizationsPaginate: state => state.app.cotizationsPaginate,
    }),
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador', 'updateItemToCar', 'setClientCarShop']),
    showItem(iten) {
      sessionStorage.setItem('cotizations-id', iten.id)
      this.$router.push({ name: 'reservations-show' })
    },
    editItem(iten) {
      sessionStorage.setItem('cotizations-id', iten.id)
      this.$router.push({ name: 'cotizations-update' })

      // localStorage.setItem('car-shop', JSON.stringify(iten.car_shop))
      // this.updateItemToCar(iten.car_shop)
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`reservations/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.reservando = false
    },

    convertReserve(reserva) {
      this.item = reserva
      this.isDialogVisible = true
    },
    confirmConvertReserve() {
      localStorage.setItem('reservations-id', this.item.id)
      localStorage.setItem('convert-to-reservation', this.item.id)

      this.setClientCarShop(this.item.cliente)

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.item.car_shop.length; index++) {
        this.item.car_shop[index].check = true
        this.item.car_shop[index].product = this.item.car_shop[index].entity
      }

      localStorage.removeItem('car-shop')
      localStorage.setItem('car-shop', JSON.stringify(this.item.car_shop))
      this.updateItemToCar(this.item.car_shop)

      setTimeout(() => {
        this.$router.push({ name: 'car-shop-items' })
      }, 50)

      this.axios
        .post(
          'reservations/noty-convert-to-reserva',
          { id: this.item.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.reservando = false
          }
        })

      // this.$router.push({ name: 'reservations-convert-to-reserve' })

      /// this.reservando = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
